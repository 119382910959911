
import Layout from '../components/layout'

import ReactDOM from 'react-dom'
import React, { useRef, useState, Suspense } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

function Scene() {
/*   const group = useRef(); */
  const [hovered, hover] = useState(false)
  const gltf = useLoader(GLTFLoader, '/models/personal-website-2.glb')
  console.log(gltf)
  return (
    <Suspense fallback={null}>
      <primitive
        scale={1}
        position={[0, -3.5, -2]}
        rotation={[0, Math.PI, 0]}  
        object={gltf.scene} 
      primitive/>
    </Suspense>
  )
}

function Box(props) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  /* useFrame((state, delta) => (ref.current.rotation.x += 0.01)) */
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 1.5 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}

const IndexPage = () => (
  <Layout>
    <section className='canvas-section'>
      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
  {/*       <Box position={[-1.2, 0, 0]} />
        <Box position={[1.2, 0, 0]} />  */}
        <Suspense fallback={null}>
          <Scene></Scene>
        </Suspense>
        <OrbitControls
          makeDefault
          minAzimuthAngle={0}
          maxAzimuthAngle={Math.PI}
          minPolarAngle={Math.PI / 3}
          maxPolarAngle={Math.PI / 3}
          enableZoom={true}
          enablePan={true}
          zoomSpeed={0.3}
        />
      </Canvas>
    </section>
  </Layout>
)

export default IndexPage
